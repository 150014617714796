import { colors } from "../ui/colors";
import styles from './home.module.css';
import baseStyles from '../ui/base.module.css';
import GridImage1 from '../ui/images/big/sheep_01.png'
import GridImage2 from '../ui/images/big/sheep_02.png'
import GridImage3 from '../ui/images/big/sheep_03.png'
import GridImage4 from '../ui/images/big/sheep_04.png'
import GridImage5 from '../ui/images/big/sheep_05.png'
import GridImage6 from '../ui/images/big/sheep_06.png'
import GridImage7 from '../ui/images/big/sheep_07.png'
import GridImage8 from '../ui/images/big/sheep_08.png'
import GridImage9 from '../ui/images/big/sheep_09.png'
import GridImage10 from '../ui/images/big/sheep_10.png'
import GridImage11 from '../ui/images/big/sheep_11.png'
import GridImage12 from '../ui/images/big/sheep_12.png'
import GridImage13 from '../ui/images/big/sheep_13.png'
import GridImage14 from '../ui/images/big/sheep_14.png'
import GridImage15 from '../ui/images/big/sheep_15.png'

import DNAImage from '../ui/images/DNA_03.png'
import BWImage from '../ui/images/bw_green_sheep_5.png'
import CapsuleImage from '../ui/images/capsule_01.png'

import { LayoutType, Section, SectionDropdown } from "../ui/section";
import { Skeleton } from "../ui/skeleton";
import { useState } from "react";

const sheepImages2 = [
    GridImage2,
    GridImage11,
    GridImage8,
    GridImage5,
    GridImage15,
    GridImage4,
    GridImage6,
    GridImage7,
    GridImage3,
    GridImage1,
    GridImage10,
    GridImage12,
    GridImage13,
    GridImage14,
    GridImage9,
]
const sheepImages = [
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
   ...sheepImages2,
]

const gridTitle = [[
    'D', 'I', 'P', 'P', 'Y'
], [
    'S', 'H', 'E', 'E', 'P', 
]]

const titleColors2 = [
    ['#f5db9b', '#f2c348', '#dd9f14', '#8a5a00'],
    [ '#ff90ae', '#f25e87', '#d23660', '#951344' ],
    [ '#b889f9', '#8964ce', '#6e4baf', '#4c337b' ],
    [ '#69c7fe', '#3e95e7', '#20489e', '#1a3369' ],
    [ '#5fddd7', '#00c7bf', '#00939a', '#014446' ],
    [ '#e2f431', '#b3de00', '#7ebc00', '#3e5810' ],
    [ '#b8545d', '#881e3c', '#710031', '#4d0c29' ],
    [ '#efa956', '#ec893a', '#df6707', '#a34107' ],
    [ '#6f9059', '#52713e', '#425530', '#283717' ],
    [ '#dddcc0', '#9c9b87', '#6a6a5d', '#3b3b35' ],
    [ '#f3eac9', '#ddca91', '#bca883', '#7c6d51' ],
    [ '#646d77', '#424a53', '#292c33', '#000004' ],
    [ '#fffffe', '#f8f8ee', '#d3d2c9', '#958f8c' ],
    [ '#e4fbff', '#cdeaef', '#add0d7', '#87a6ac' ],
    [ '#faffeb', '#fbe203', '#ff9f01', '#6e3902' ],
    [ '#dcf9fe', '#00d5fd', '#008ebf', '#00415b' ],
]

const breakPoint1 = 1400
const breakPoint2 = 900

export default function InstallHome() {
    const [numberOfSheepAcross, setNumberOfSheepAcross] = useState(window.innerWidth < 700 ? 7 : 11);
    const [width, setWidth] = useState(window.innerWidth / numberOfSheepAcross);
    const mobileHeightScale = window.innerWidth < breakPoint2 ? 0.4 : 0.7
    const [numberOfSheepDown, setNumberOfSheepDown] = useState(Math.floor((window.innerHeight * mobileHeightScale) / width));
    const [height, setHeight] = useState(width * numberOfSheepDown)

    
    const onResize = () => {
        const mobileHeightScale2 = window.innerWidth < breakPoint2 ? 0.4 : 0.7
        const newNumberOfSheepAcross = window.innerWidth < breakPoint1 ? 7 : 11
        const newWidth = window.innerWidth / newNumberOfSheepAcross
        const newNumberOfSheepDown = Math.floor((window.innerHeight * mobileHeightScale2) / newWidth)
        
        setNumberOfSheepAcross(newNumberOfSheepAcross)
        setWidth(newWidth)
        setNumberOfSheepDown(newNumberOfSheepDown)
        setHeight(newWidth * newNumberOfSheepDown)
    }
    window.addEventListener('resize', onResize)
  return (
   <Skeleton>
        <div className={styles.grid}
            style={{
                height: height + 'px'
            }}
        >
            <div
                className={styles.gridTitle}
                style={{
                    top: Math.floor(numberOfSheepDown / 3) * width + 'px',
                    left: width * ((numberOfSheepAcross - 5) / 2) + 'px',
                }}
            >
                {gridTitle.map((word, index) => {
                    return <div className={styles.gridTitleRow}>
                        {word.map((letter, i) => {
                            return <div
                                className={`${styles.gridImages} ${styles.gridTitleCard}`}
                                style={{
                                    backgroundColor: titleColors2[(index * 5) + i][0],
                                    color: titleColors2[(index * 5) + i][2],
                                    width: width + 'px',
                                    height: width + 'px',
                                    fontSize: width * 0.7,
                                }}
                            >{letter}</div>
                        })}
                        </div>
                })}
            </div>
            {sheepImages.map(sheep => {
                return <img
                    src={sheep}
                    className={styles.gridImages}
                    alt={""}
                    style={{
                        width: width + 'px',
                        height: width + 'px',
                    }}
                />
            })}
        </div>



        <Section padding={false}>
            <div style={{width: '70%', textAlign: 'center', padding: '54px'}}>
                <h1 style={{fontSize: '66px'}}>a new era</h1>
                <p>
                    It's the golden age of web3, theres so much room for invention, creativity and community support. We believe there will never be a better time than right now to come together and create something unique.

                </p>
            </div>
        </Section>

        <Section
            layout={LayoutType.left}    
        >
            <img src={CapsuleImage} className={styles.sectionImage}  alt={""}/>
            <div style={{width: '100%'}}>
                <h1>
                    Phase 1: Genesis
                </h1>
                <h3>
                    Experimentation · Play · Loyalty
                </h3>
                <p>
                    The Dippy Sheep are a collection of 10,000 randomly generated sheep with hundreds of unique traits and personalities.
                <p></p>
                    We have developed a richly detailed ecosystem with an emphasis on 
                    storytelling and interactivity. This project will always have experimentation and play at heart - we're here for the long run.
                </p>
            </div>
        </Section>
        <Section
            layout={LayoutType.right}
        >
            <img src={DNAImage} className={styles.sectionImageTall}  alt={""}/>
            <div>
                <h1>Phase 2: The Cloning</h1>
                <p>
                    The Dippy Sheep have been captured by a mad scientist who is collecting and altering their DNA in his lab.
                </p>
                <p>
                    Their DNA grows and mutates based on the blockchain activity for the Dippy Sheep Collection.
                    As a holder, you will get to shape and change the DNA strands of your sheep, directly influencing what
                    new creatures spawn into your wallet from the Cloning Event.
                </p>
                <p>
                    The following factors will contribute to the number and type of DNA blocks each sheep will have:
                </p>
                <p>
                    <ul>
                        <li>Time since last sale (blocks get reset upon sale)</li>
                        <li>Price the token was last sold for (higher price = more starting blocks)</li>
                        <li>Number of Secondary Sales in the collection since last transfer</li>
                        <li>Floor price since last transfer (blocks spawn as the collection grows)</li>
                    </ul>
                </p>
            </div>
        </Section>
        <Section
            layout={LayoutType.left}    
        >
            <img src={BWImage} className={styles.sectionImage} alt={""} />
            <div style={{width: '100%'}}>
                <h1>
                    Phase 3: Top Secret
                </h1>
                <p className={styles.redacted}>
                    &#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;
                </p>
                <p className={styles.redacted}>
                    &#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;&#9608;
                </p>
                <p className={styles.redacted}>
                    &#9608;&#9608;&#9608;&#9608;&#9608;&#9608;
                </p>
            </div>
        </Section>
        <Section
            background={colors.bgDark}
        >
            <h1>FAQ</h1>
            <SectionDropdown
                title={"What are the dippy sheep?"}
                description={<div className={styles.sectionDropdownInner}>
                    <p>
                        10,000 randomly generated NFT's from 200+ unique traits. Using the ERC-721 Token on the Etherium Blockchain.
                    </p>
                </div>    
            }
            />
            <SectionDropdown
                title={"What is the Flock List?"}
                description={<div className={styles.sectionDropdownInner}>
                        <p>
                            The Flock List is for the OG supporters of the project, who believed in our vision.
                        </p>
                        <p>
                            Flock List = free mint
                        </p>
                    </div>    
                }
            />
            <SectionDropdown
                title={"How do I get whitelisted?"}
                description={<div className={styles.sectionDropdownInner}>
                        <p>
                            The whitelist will be won through twitter contests and engagement.
                        </p>
                        <p>
                            We reserve 10 whitelist spots to community spirit. We are building something that will last and showing the world the positive vibes that NFT's can have is the most important goal for us.
                        </p>
                    </div>    
                }
            />
            <SectionDropdown
                title={"What are the mint prices?"}
                description={<div className={styles.sectionDropdownInner}>
                    <p>Flock List - Free mint</p>
                    <p>Mint Prices TBD</p>
                </div>}
            />

            <SectionDropdown
                title={"About the creators"}
                description={<div className={styles.sectionDropdownInner}>
                    <Section
                        padding={false}
                        layout={LayoutType.left}
                    >
                        <img src={GridImage2} className={styles.sectionImageSmall} alt={""}/>
                        <div style={{width: '100%'}}>
                            <h4 className={baseStyles.headerSmall}>Dippy</h4>
                            <div className={styles.iconLink}>
                                <h5 className={baseStyles.headerTiny} style={{display: 'inline'}}>Pixel artist</h5>
                            </div>
                            <p>
                                Pixel obsessed, you'll find them chaotically creating new one of a kind attributes for the sheep until the last minute.
                            </p>
                        </div>
                    </Section>
                    <Section
                        padding={false}
                        layout={LayoutType.left}
                    >
                        <img src={GridImage1} className={styles.sectionImageSmall} alt={""} />
                        <div style={{width: '100%'}}>
                            <h4 className={baseStyles.headerSmall}>The Shepard</h4>
                            <div className={styles.iconLink}>
                                <h5 className={baseStyles.headerTiny} style={{display: 'inline'}}>Lead Engineer</h5>
                            </div>
                            <p>
                                Likes code. Doesn't like Dippy.
                            </p>
                        </div>
                    </Section>
                </div>}
            />

        </Section>
    </Skeleton>
  );
}