import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import InstallHome from "./pages/home/install";
import { trackScreenView } from "./firebase";
import { useEffect } from "react";

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function App() {
  let location = useLocation();

  useEffect(() => {
    trackScreenView(location.pathname)
  }, [location])

  return (
    <Routes>
      <Route path="/" element={<InstallHome />}/>
    </Routes>
  );
}