import styles from './base.module.css';

export const Skeleton = (props: React.PropsWithChildren) => {
  return (
    <div className={styles.pageContainer}>
        <div className={styles.bodyContainer}>
          {props.children}
        </div>
        <div className={styles.footerContainer}>
            
        </div>
    </div>
  );
}