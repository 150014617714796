export const colors = {
    black: '#000004',
    white: '#e4fbff',
    // dark to light
    grey4: '#4f4f4f',
    grey3: '#292c33',
    grey2: '#424a53',
    grey1: '#646d77',

    white4: '#87a6ac',
    white3: '#add0d7',
    white2: '#cdeaef',
    white1: '#e4fbff',

    // bg: '#fff181',
    // turq: '#12d0c8',
    // green: '#82dd00',

    
    // bg: '#e8ff92',
    // bgDark: '#d4ee74',
    // turq: '#00c2e6',
    // green: '#a278f1',

    bg: '#93efeb',
    bgDark: '#78e6e1',
    turq: '#00c2e6',
    green: '#a278f1',

    bg_green: '#abd55f',
    bg_teal: '#94efeb',
    bg_purple: '#c5a1f6',
    bg_yellow: '#f0e97c',
    bg_pink: '#f5b4c5',
    bg_ocean: '#c9d8db',
    bg_bone: '#d3d2c9',

  }