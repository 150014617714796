import styles from './section.module.css';
import Collapsible from 'react-collapsible';
import './collapsible.css';
import { useState } from "react";


export enum LayoutType {
    left,
    right,
    columnTwo,
}

export type SectionProps = {
    background?: string,
    fullHeight?: boolean,
    halfHeight?: boolean,
    layout?: LayoutType,
    padding?: boolean,
}

export const Section = (props: React.PropsWithChildren<SectionProps>) => {
    return (
        <div
            className={styles.sectionContainer}
            style={{
                backgroundColor: props.background,
                padding: props.padding || props.padding === undefined ? '100px 16px' : undefined,
                minHeight: props.fullHeight ? window.innerHeight - 32 : props.halfHeight ? (window.innerHeight / 2) - 32: undefined,
            }}
        >
            <div
                className={`${styles.sectionInnerContainer} ${props.layout === LayoutType.columnTwo ? styles.sectionContatinerColumnTwo : props.layout === LayoutType.left ? styles.sectionContatinerLeft : props.layout === LayoutType.right ? styles.sectionContatinerRight : undefined}`}
            >
            {props.children}
          </div>
        </div>
      );

}

export type SectionDropdownProps = {
    title: string,
    description: string | JSX.Element,
}

export const SectionDropdown = (props: React.PropsWithChildren<SectionDropdownProps>) => {
    const [isOpen, setIsOpen] = useState(false);
    
    return (
        <>
        <Collapsible
            transitionTime={200}
            onOpening={() => {setIsOpen(true)}}
            onClosing={() => {setIsOpen(false)}}
            trigger={<div className={styles.collabsibleTitle}>
                <div>{props.title}</div>
                <div
                    className={`${styles.chevron} ${styles.chevron1} ${styles.chevronstartdown} ${isOpen ? styles.active : undefined}`}
                >
                    <span></span>
                    <span></span>
                </div>
            </div>
            }
            className={styles.sectionDropdownContainer}
            triggerClassName={styles.triggerClassName}
        >
            <p>
                {props.description}
            </p>
            
        </Collapsible>
        <div className={styles.collabsibleDivider}></div>
        </>
    );

}
