import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCCSwpUNcDBQzmfRAR21kYZpl5baeNA9d8",
  authDomain: "dippy-sheep.firebaseapp.com",
  projectId: "dippy-sheep",
  storageBucket: "dippy-sheep.appspot.com",
  messagingSenderId: "408642682132",
  appId: "1:408642682132:web:12f6119d0bfc503c789601",
  measurementId: "G-GFCPK2HPDY"
};


export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const trackScreenView = (screenName: string) => {
    logEvent(analytics, 'screen_view', {
        firebase_screen: screenName, 
        firebase_screen_class: screenName
      });
}